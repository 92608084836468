import './App.css';
import React, { useState } from "react";
import ListPicker from "./components/ListPicker";
import InWisconsinMap from "./components/InWisconsinMap";
import { LightningBoltIcon } from "@heroicons/react/outline";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  const BED_OPTIONS = [
    { id: null, name: 'Any Beds' },
    { id: 1, name: '1 BR' },
    { id: 2, name: '2 BR' },
    { id: 3, name: '3 BR' },
    { id: 4, name: '4 BR' },
    { id: 5, name: '5+ BR' }
  ];


  const BATH_OPTIONS = [
    { id: null, name: 'Any Baths' },
    { id: 1, name: '1+ BA' },
    { id: 2, name: '2+ BA' },
    { id: 3, name: '3+ BA' }
  ];

  const RENTABLE_TYPE_OPTIONS = [
    { id: null, name: 'Any Type' },
    { id: 2, name: 'House' },
    { id: 3, name: 'Apartment' }
  ];

  const HOMES_TYPE_OPTIONS = [
    { id: null, name: 'Any Type' },
    { id: "HOUSE", name: 'House' },
    { id: "MULTI_FAMILY", name: 'Multi-Family' },
    { id: "CONDOMINIUM", name: 'Condo' },
    { id: "TOWNHOUSE", name: 'Townhouse' },
    { id: "LOTS_LAND", name: 'Lots/Land' },
    { id: "MOBILE_MANUFACTURED", name: 'Manufactured' }
  ];

  const [cityValue, setCityValue] = useState("Madison, WI");
  const [selectedSearchCategory, setSelectedSearchCategory] = useState("rent");
  const [bedsValue, setBedsValue] = useState(BED_OPTIONS[0]);
  const [bathsValue, setBathsValue] = useState(BATH_OPTIONS[0]);
  const [propertyTypeValue, setPropertyTypeValue] = useState(RENTABLE_TYPE_OPTIONS[0]);

  const mapOnChange = newCityValue => {
    console.log("mapOnChanged:");
    setCityValue(newCityValue)
  };

  const getLogoAttribution = () => {
    if (selectedSearchCategory === "buy") {
      return (
        <a href="https://www.homes.com/widgets/" target="_blank" rel="nofollow noreferrer" title="Homes.com" className="homes-logo">
          Powered By Homes.com
        </a>
      );
    }
    return (
      <a href="https://www.rentable.co" rel="noreferrer" target="_blank" title="rentable.co" className="flex">
        <LightningBoltIcon className="h-5 w-5" aria-hidden="true" /> Powered By Rentable
      </a>
    );
  };

  const submitForm = () => {
    // NelsonSchmidt tracking
    const evp = document.createElement("img");
    evp.height = 1;
    evp.width = 1;
    evp.style.display = "none";
    evp.src = `https://bm.adentifi.com/pixel/conv/ppt=4313;g=find_a_home_search_button_kpi;gid=18703;ord=${new Date().getTime()}${parseInt(
      Math.random() * 10000000000000,
      10
    )}`;
    document.body.append(evp);

    let url = "";
    if (selectedSearchCategory === "buy") {
      url = buildHomesUrl();
    } else{
      url = buildRentableUrl();
    }
    window.open(url, "_blank");
  };

  const buildHomesUrl = () => {
    let url = "https://www.homes.com/";

    if (cityValue !== "") {
      const strings = cityValue.toLowerCase().split(", ");
      url += `${strings[0].replace(/\s/g, "-")}-${strings[1]}/`;
    }
    url += "/homes-for-sale";
    if (bedsValue !== null && bedsValue.id !== null) {
      url += `/${bedsValue.id}-bedroom`;
    }
    let params = [];
    if (bathsValue !== null && bathsValue.id !== null) {
      params.push(`baths=${bathsValue.id}`);
    }

    if (propertyTypeValue !== null && propertyTypeValue.id !== null) {
      params.push(`property_type=${propertyTypeValue.id}`);
    }

    if (params.length > 0) {
      url += "?";
      url += params.join("&");
    }
    return url;
  };

  const buildRentableUrl = () => {
    let url = "https://www.rentable.co/";
    if (cityValue !== "") {
      const strings = cityValue.toLowerCase().split(", ");
      url += `${strings[0].replace(/\s/g, "-")}-${strings[1]}?`;
    }

    if (bedsValue !== null && bedsValue.id !== null) {
      url += `&bedrooms[]=${bedsValue.id}`;
    }

    if (bathsValue !== null && bathsValue.id !== null) {
      url += `&bathrooms[]=${bathsValue.id}`;
    }

    if (propertyTypeValue !== null && propertyTypeValue.id !== null) {
      url += `&propertytype[]=${propertyTypeValue.id}`;
    }

    url += "&utm_source=inwisconsin&utm_medium=referral&utm_campaign=find_a_home";

    return url;
  };

  const switchCategory = (newCategory) => {
    setSelectedSearchCategory(newCategory)
    setBedsValue(BED_OPTIONS[0]);
    setBathsValue(BATH_OPTIONS[0]);
    setPropertyTypeValue(RENTABLE_TYPE_OPTIONS[0]);
  }

  const btnClasses = "relative inline-flex items-center px-4 py-2 border border-gray-300 text-lg";
  return (
    <div className="">
      <div className="">
        <div className="flex items-center content-section content-section-short vertical-align-contents">
          <div className="flex-1">
            <div>
              <InWisconsinMap onChange={mapOnChange} />
            </div>
          </div>
          <div className="flex-1">
            <div className="pr-8">
              <h1 className="headline">FIND YOUR HOME</h1>
              <p className="find-a-home-content-description">Use this tool to find your next home to rent or buy.</p>
              <div className="mt-4">
                <span className="relative z-0 inline-flex shadow-sm rounded-md">
                  <button
                    type="button"
                    className={classNames(
                      selectedSearchCategory === "rent" ? "bg-sky-700 text-white rounded-l-md" : "text-slate-500 rounded-l-md",
                      btnClasses
                    )}
                    onClick={e => switchCategory("rent")}
                  >
                    For Rent
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      selectedSearchCategory === "buy" ? "bg-sky-700 text-white rounded-r-md" : "text-slate-500 rounded-r-md",
                      btnClasses
                    )}
                    onClick={e => switchCategory("buy")}
                  >
                    For Sale
                  </button>
                </span>
              </div>
              <div className="mt-4">
                <div className="flex-1">
                  <input
                    className="city-text-input outline-0"
                    placeholder="Enter a City"
                    value={cityValue}
                    onChange={event => setCityValue(event.target.value)}
                  />
                </div>
              </div>
              <div className="mt-4 flex justify-between gap-x-6">
                <div className="flex-1">
                  <ListPicker optionList={BED_OPTIONS} onSelected={setBedsValue} selectedOption={bedsValue} />
                </div>
                <div className="flex-1">
                  <ListPicker optionList={BATH_OPTIONS} onSelected={setBathsValue} selectedOption={bathsValue}/>
                </div>
                <div className="flex-1">
                  <ListPicker optionList={selectedSearchCategory === "rent" ? RENTABLE_TYPE_OPTIONS : HOMES_TYPE_OPTIONS}  onSelected={setPropertyTypeValue} selectedOption={propertyTypeValue}/>
                </div>
              </div>
              <div className="mt-4 flex justify-end">
                <div className="">
                  <input
                    type="button"
                    className="relative inline-flex items-center px-4 py-2 rounded-md border border-gray-300 bg-sky-700 text-lg font-bold text-white cursor-pointer"
                    value="Search"
                    onClick={submitForm}
                  />
                </div>
              </div>
              <div className="mt-4 flex justify-end h-5">{getLogoAttribution()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;

import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as InWisconsinSVG } from "../in_wisconsin.svg";

class InWisconsinMap extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired
  };

  static defaultProps = {
    regions: [
      { id: "south-central-home", value: "Madison, WI" },
      { id: "central-home", value: "Wausau, WI" },
      { id: "west-central-home", value: "Eau Claire, WI" },
      { id: "southwest-home", value: "Platteville, WI" },
      { id: "southeast-home", value: "Milwaukee, WI" },
      { id: "northeast-home", value: "Green Bay, WI" },
      { id: "north-home", value: "Rhinelander, WI" },
      { id: "northwest-home", value: "Superior, WI" }
    ]
  };

  state = {
    selectedIndex: 0
  };

  componentDidMount() {
    this.setupMapEventListeners();
  }

  setupMapEventListeners = () => {
    this.props.regions.forEach(regionObj => {
      const region = this.getMapRegion(regionObj.id);
      region.addEventListener("mouseover", this.handleMapMouseEvent, false);
      region.addEventListener("mouseout", this.handleMapMouseEvent, false);
      region.addEventListener("click", this.handleMapClick, false);
    });
  };

  handleMapClick = e => {
    const regionId = e.target.id;
    this.clearMapRegions();
    this.getMapRegion(regionId).classList.add("region-selected");
    this.getMapPinForRegion(regionId).classList.add("region-pin-selected");
    this.getMapTextForRegion(regionId).classList.add("region-text-selected");

    this.setState({
      selectedIndex: this.getSelectedIndexForRegionId(regionId)
    });
    this.props.onChange(this.getRegionData(regionId).value)
  };

  handleMapMouseEvent = e => {
    // if this region is selected, we dont want this
    const { id } = this.props.regions[this.state.selectedIndex];
    if (e.target.id === id) {
      return;
    }

    const regionPin = this.getMapPinForRegion(e.target.id);
    const regionText = this.getMapTextForRegion(e.target.id);
    regionPin.classList.toggle("region-pin-selected");
    regionText.classList.toggle("region-text-selected");
  };

  clearMapRegions() {
    const { id } = this.props.regions[this.state.selectedIndex];
    this.getMapRegion(id).classList.remove("region-selected");
    this.getMapPinForRegion(id).classList.remove("region-pin-selected");
    this.getMapTextForRegion(id).classList.remove("region-text-selected");
  }

  getSelectedIndexForRegionId(regionId) {
    let selectedIndex = 0;
    this.props.regions.forEach((obj, index) => {
      if (obj.id === regionId) {
        selectedIndex = index;
      }
    });
    return selectedIndex;
  }

  getRegionData(regionId) {
    let regionObj = null;
    this.props.regions.forEach(obj => {
      if (obj.id === regionId) {
        regionObj = obj;
      }
    });
    return regionObj;
  }

  getMapRegion(elementId) {
    return document.getElementById(elementId);
  }

  getMapPinForRegion(regionId) {
    return document.getElementById(`${regionId}-pin`);
  }

  getMapTextForRegion(regionId) {
    return document.getElementById(`${regionId}-text`);
  }

  render() {
    return (
      <div className="rql-secondary-container">
        <div className="rql-secondary-section">
          <div className="rql-home-svg-content">
            <InWisconsinSVG />
          </div>
        </div>
      </div>
    );
  }
}

export default InWisconsinMap;
